<template>
  <div class="chart-container">
    <div class="chart-header">
      <h3>{{ title || `Chart ${graphNo + 1}` }}</h3>
      <div class="merge-dropdown">
        <label for="merge-select">Merge with: </label>
        <select id="merge-select" v-model="selectedMergeChart" @change="handleMergeSelection">
          <option value="">Select a chart</option>
          <option v-for="(option, index) in mergeOptions" :key="index" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>
      <v-btn icon small @click="$emit('closeChart', graphNo)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div
      :id="'chart-'+graphNo"
      @mouseenter="$emit('enableScroll', $event)"
      @mouseleave="$emit('disableScroll', $event)"
    ></div>
  </div>
</template>

<script setup>
import ApexCharts from 'apexcharts'
import { defineProps, onMounted, ref, watch, defineEmits, computed } from 'vue'

const props = defineProps({
  series: Array,
  graphNo: Number,
  noGraphs: Number,
  unit: String,
  title: String,
  allCharts: Array
});


const genColor = (x) => {
  if (x==0) return '#228B22';
  if (x==1) return '#FF0000';
  if (x==2) return '#FF1493';
  if (x==3) return '#FFA500';
  if (x==4) return '#FFFF00'
}

const emit = defineEmits(['close', 'enableScroll', 'disableScroll', 'closeChart', 'mergeCharts']);

const chart = ref(null);
const selectedMergeChart = ref('');

const mergeOptions = computed(() => {
  return props.allCharts
    .map((chart, index) => ({
      value: index,
      label: chart[0].name || `Chart ${index + 1}`
    }))
    .filter((option) => option.value !== props.graphNo);
});

const handleMergeSelection = () => {
  if (selectedMergeChart.value !== '') {
    emit('mergeCharts', props.graphNo, parseInt(selectedMergeChart.value));
    selectedMergeChart.value = ''; // Reset selection after merging
  }
};

const updateChart = (series) => {
  if (chart.value && chart.value != null) {
    chart.value.updateSeries(series)
    chart.value.updateOptions({
       yaxis: {
        title: {
          text: props.unit,
        }
      }
    });
  }
}

const initChart = (series, graphNo) => {
  const options = {
    chart: {
      type: 'line',
      fontFamily: 'InterVariable',
      foreColor: '#0F172A',
      fontWeight: 400,
      height: 335,
      defaultLocale: 'en',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
          customIcons: []
        },
      },
      zoom: {
        enabled: true
      }
    },
    labels: {
      style: {
        fontSize: '15px',
      }
    },
    legend: {
      showForSingleSeries: true,
      fontFamily: 'InterVariable',
      fontWeight: 600,
      horizontalAlign: 'left',
      markers: {
        width: 8,
        height: 8,
      },
      offsetY: 18,
      itemMargin: {
        vertical: 12
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 0,
    },
    series: series,
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      title: {
        text: props.unit,
        style: {
          fontSize: '12px',
        }
      },
      labels: {
        style: {
          fontSize: '12px',
        }
      },
      decimalsInFloat: 1,
    },
    markers: {
      size: 5
    },
    tooltip: {
      enabled: true,
      followCursor: false
    }
  }
  chart.value = new ApexCharts(document.querySelector("#chart-"+graphNo), options)
  chart.value.render()
}

onMounted(() => {
  initChart([], props.graphNo)
  if (props.series.length > 0 && chart.value != null) {
    const newSeries = []
    let x=0;
    for (let s of props.series) {
      newSeries.push({
        name: s.name.includes("and") ? s.name.split("and")[0] : s.name, 
        data: s.data,
        color: genColor(x)
      })
      x++;
    }
    updateChart(newSeries, props)
  }
})

watch(() => props.series, (series) => {
  if (series == undefined) return
  const newSeries = []
  let x=0;
  for (let s of series) {
    newSeries.push({
      name: s.name.includes("and") ? s.name.split("and")[0] : s.name, 
      data: s.data,
      color: genColor(x)
    })
    x++;
  }
  updateChart(newSeries)
})
</script>

<style scoped>
.chart-container {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
}
.chart-header h3 {
  margin: 0;
  font-size: 1.1em;
}
.merge-dropdown {
  margin-right: 10px;
}
.merge-dropdown select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
</style>