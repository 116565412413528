<template>
  <v-alert
    color="warning"
    variant="tonal"
    closable
    class="mb-4 disclaimer-alert"
    @click:close="$emit('closeDisclaimer')"
  >
    <div class="disclaimer-content">
      <p>
        You have selected 2 or more datasets to analyse. Note that selected datasets
        may have different parameters and geographic locations.
      </p>

      <p>
        Please refer to the metadata file
        <a href="#" class="text-info text-decoration-underline">here</a>
        to see specifications of the datasets: <strong>{{ formattedDatasetNames }}</strong>.
      </p>

      <p>
        Please confirm you have read metadata before proceeding further with data
        interpretation and analysis. Use associated supplementary materials to explore
        the context of the data.
      </p>
    </div>
  </v-alert>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  datasetNames: {
    type: Array,
    required: true
  }
})

const formattedDatasetNames = computed(() => {
  return props.datasetNames.join(', ')
})
</script>

<style scoped>
.disclaimer-alert {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.disclaimer-content {
  color: #37474f;
}

.disclaimer-content p {
  margin-bottom: 8px;
}

.disclaimer-content p:last-child {
  margin-bottom: 0;
}

a {
  color: inherit;
}

a:hover {
  opacity: 0.8;
}
</style>