<template>
  <v-card class="sample-type-card">
    <div class="section-title">Sample type</div>
    <v-divider></v-divider>

    <div >
      <template v-for="(option, index) in sampleTypes" :key="index">
        <SampleTypeNode :index="2" :option='option' :overrideEnabled='false' @onStatusChange="onChange"/>
      </template>
    </div>
  </v-card>
</template>

<script setup>
import { ref, onMounted, inject} from 'vue';
import SampleTypeNode from './SampleTypeNode.vue'
const ParameterService = inject('ParameterService');

const sampleTypes = ref({});
let selectedList = [];

import useEmitter from '@/plugins/useEmitter'
const emitter = useEmitter()

onMounted(async () => {
  sampleTypes.value = await ParameterService.getSampleTypes();
});

const onChange = (changedItem) => {
  const found = selectedList.find((e) => e.id == changedItem.id);
  if (found == undefined ) selectedList.push(changedItem);
  else found.status=changedItem.status;
  let refinedList=[];
  for (const selectedItem of selectedList) {
    if (selectedItem.status) refinedList=refinedList.concat(refine(selectedItem));
  }
  const finalList = [...new Set(refinedList)];
  emitter.emit('sampleType', finalList)
}

const refine = (item) => {
  let returnArray = [];
  returnArray.push({id:item.id})
  for (const subItem of item.children) returnArray = returnArray.concat(refine(subItem))
  return returnArray;
}


</script>

<style scoped>
.sample-type-card {
  padding: 16px;
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d7e1;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.option-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 8px;
  align-items: center;
}
</style>