import { DateTime } from "luxon";

export async function resolveCSV2(dataItems) {
	let results = [];
	for (const dataItem of dataItems) {
		const rawData = await (await fetch(dataItem.url)).text();
		const lines  = rawData.split("\n");
		let locations = [];
		for (let i=1; i < lines.length;i++) {
			const line = lines[i].split(",");
			const location = Number(line[1]).toFixed(3)+","+Number(line[2]).toFixed(3);
			if (locations.includes(location)) continue;
			locations.push(location)
			let newResult = { ...dataItem };
			newResult.location=location;
			newResult.id=newResult.id+"_"+locations.length;
			results.push(newResult);
		}
	}
	return results;
}

export async function loadCSV2(dataItem) {
	const rawData = await (await fetch(dataItem.url)).text();
	const lines  = rawData.split("\n");
	let results = [];
	for (let i=1; i < lines.length;i++) {
		const line = lines[i].split(",");
		const location = Number(line[1]).toFixed(3)+","+Number(line[2]).toFixed(3);
		if (location==dataItem.location) {
			let dateTime;
			if (line[0].includes(":")) {
				dateTime = DateTime.fromFormat(line[0],"dd/MM/yyyy HH:mm");
				if (dateTime.isValid) line[0]=dateTime.toISO();
				else console.log(dateTime)
			} else {
				dateTime = DateTime.fromFormat(line[0],"dd/MM/yyyy");
				if (dateTime.isValid) line[0]=dateTime.toISO();
				else console.log(dateTime)
			}
			let newResult = { x: line[0], y: Number(line[Number(dataItem.identifier)]) };
			results.push(newResult);	
		}
		
	}
	return results;
}