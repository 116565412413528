<template>
  <v-app-bar app color="primary" dark>
    <v-toolbar-title>Freshwater Gateway Project</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text @click="goHome()">Home / Map View</v-btn>
    <v-btn text @click="goGraphs()">Data Analysis</v-btn>
  </v-app-bar>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import useEmitter from '@/plugins/useEmitter'

const router = useRouter()
const emitter = useEmitter()
const DataService = inject('DataService')

// Make graphs and mapLocation reactive
const graphs = ref([])
const mapLocation = ref({})

// Handler for Home navigation
const goHome = async () => {
  const localValue = mapLocation.value
  await router.push('/')
  emitter.emit('goHome', localValue)
}

// Handler for Graph navigation
const goGraphs = async () => {
  await router.push('/graph')
  emitter.emit('goGraphs', graphs.value)
}

// Event Listeners
emitter.on("viewgraph", async function (value) {
  try {
    console.log("Fetching data for:", value)
    const data = await DataService.getData(value)
    const newSeries = {
      unit: value.unitText,
      name: value.title + "(" + value.location + ")",
      data: data
    }
    graphs.value.unshift([newSeries])
    await router.push('/graph')
    emitter.emit('goGraphs', graphs.value)
    console.log("Added new graph series:", newSeries)
  } catch (error) {
    console.error("Error processing graph data:", error)
  }
})

emitter.on("mapmoved", value => {
  mapLocation.value = value
  console.log("Map location updated:", value)
})

emitter.on("changeGraph", value => {
  graphs.value = value
  console.log("Graphs updated:", value)
})
</script>

<style scoped>
.v-app-bar {
  background-color: #2c82de;
}

.v-btn {
  color: white;
}

/* Add hover effect for buttons */
.v-btn:hover {
  opacity: 0.9;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Add active state for buttons */
.v-btn:active {
  transform: scale(0.98);
}

/* Style for toolbar title */
.v-toolbar-title {
  font-weight: 600;
  letter-spacing: 0.5px;
}
</style>