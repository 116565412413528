<template>
  <v-dialog
    v-model="dialog"
    width="800"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <v-card>
      <v-card-title class="text-h5 bg-primary text-white pa-4 d-flex justify-space-between align-center">
        Dataset Information
        <v-btn
          icon="mdi-close"
          variant="text"
          @click="$emit('update:modelValue', false)"
          color="white"
        ></v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <!-- Dataset Overview Section -->
        <div class="my-4">
          <h3 class="text-h6 mb-2">Dataset Overview</h3>
          <v-divider class="mb-4"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Name of the dataset:</div>
                <div class="metadata-value">{{ result.title }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Data provider:</div>
                <div class="metadata-value">{{ result.organisation.split('/').pop() }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Timeframe:</div>
                <div class="metadata-value">{{ formatDate(result.startDate) }} - {{ formatDate(result.endDate) }}</div>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Parameter Details Section -->
        <div class="my-4">
          <h3 class="text-h6 mb-2">Parameter Details</h3>
          <v-divider class="mb-4"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Name of the parameter:</div>
                <div class="metadata-value">{{ result.title }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Parameter symbol:</div>
                <div class="metadata-value">{{ result.symbol || 'Not specified' }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Unit:</div>
                <div class="metadata-value">{{ result.unitText || 'Not specified' }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Limit of detection:</div>
                <div class="metadata-value">{{ result.detectionLimit || 'Not specified' }}</div>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Sampling Details Section -->
        <div class="my-4">
          <h3 class="text-h6 mb-2">Sampling Details</h3>
          <v-divider class="mb-4"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Sampling approach:</div>
                <div class="metadata-value">{{ result.samplingApproach.split('/').pop() }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Sample type:</div>
                <div class="metadata-value">{{ result.sampleType.split('/').pop() }}</div>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Quality Assurance Section -->
        <div class="my-4">
          <h3 class="text-h6 mb-2">Quality Assurance</h3>
          <v-divider class="mb-4"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Data quality assurance:</div>
                <div class="metadata-value">{{ result.suitabilityIndicator.split('/').pop() }}</div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="d-flex metadata-item">
                <div class="metadata-label">Application type:</div>
                <div class="metadata-value">{{ result.application.split('/').pop() }}</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, defineProps, emit, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  result: {
    type: Object,
    required: true
  }
});

defineEmits(['update:modelValue']);

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const formatDate = (dateStr) => {
  if (!dateStr) return 'Not specified';
  try {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  } catch (e) {
    return 'Invalid date';
  }
};
</script>

<style scoped>
.metadata-item {
  margin-bottom: 12px;
  gap: 16px;
}

.metadata-label {
  min-width: 200px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.metadata-value {
  flex: 1;
}

:deep(.v-card-title) {
  word-break: break-word;
}

:deep(.v-dialog > .v-card > .v-card-text) {
  padding-bottom: 20px;
}
</style>