import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'mapbox-gl/dist/mapbox-gl.css';

import createKindeClient from "@kinde-oss/kinde-auth-pkce-js";

import 'vuetify/styles';
// Import Material Design Icons;
import '@mdi/font/css/materialdesignicons.css';

import router from './router';

loadFonts();
import mitt from 'mitt';
const emitter = mitt();


const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
  console.error('Global error:', err);
  console.error('Error info:', info);
};
app.config.globalProperties.emitter = emitter;
app.use(vuetify);
app.use(router);
app.mount('#app');


const kinde = await createKindeClient({
  client_id: 'c82f76dfe013489c9d27a58c0e62f06e',
  domain: "https://freshwatergateway.kinde.com",
  redirect_uri: window.location.origin,
  on_redirect_callback: (user, appState) => {
    console.log({user, appState});
    if (user) {
      // render logged in view
    } else {
      kinde.login();
    }
  }
});



// app.config.globalProperties.$mapboxgl = mapboxgl