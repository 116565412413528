<template>
  <v-card class="suitability-indicators-card">
    <div class="section-title">Suitability indicators</div>
    <v-divider></v-divider>
    <div class="option-grid">
      <template v-for="suitabilityIndicator in suitabilityIndicators" :key="suitabilityIndicator.id">
        <div class="option-row">
          <v-switch
            v-model="suitabilityIndicator.enabled"
            @change="emitter.emit('suitabilityindicator', {id:suitabilityIndicator.id,status:suitabilityIndicator.enabled})"
            class="custom-switch"
            inset
            hide-details
            color="success"
          />
          <div
            :class="['option-label', { 'active-label': suitabilityIndicator.enabled }]"
          >
            {{ suitabilityIndicator.title }}
          </div>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script setup>
import { ref, onMounted, inject} from 'vue';
const ParameterService = inject('ParameterService');

const suitabilityIndicators = ref({});

onMounted(async () => {
  suitabilityIndicators.value = await ParameterService.getSuitabilityIndicators();
  for (const suitabilityIndicator of suitabilityIndicators.value) suitabilityIndicator.enabled=false;
});
</script>

<style scoped>
.suitability-indicators-card {
  padding: 16px;
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d7e1;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.option-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
  align-items: center;
}

.option-row {
  display: contents;
}

.option-label {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active-label {
  font-weight: bold;
  color: #000;
}

.custom-switch {
  margin-right: 0;
}

/* Custom styles for v-switch */
:deep(.v-input--switch) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.v-input--switch .v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-input--switch__track) {
  opacity: 1 !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__track) {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

:deep(.v-input--switch__thumb) {
  color: white !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__thumb) {
  transform: translate(16px, 0);
}
</style>