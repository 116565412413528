const url = "./rawdata/fishsurvey.csv";


export async function resolveFISHSURVEY(dataItems) {
	const rawData = await (await fetch(url)).text();
	const lines  = rawData.split("\n");
	let results = [];
	for (let i=1; i < lines.length;i++) {
		const line = lines[i].split(",");
		let x=0;
		for (const dataItem of dataItems) {
			if (line[0]==dataItem.identifier) {
				let newResult = { ...dataItem };
				newResult.id=newResult.id+'_'+i+'_'+x;
				x++;
				newResult.location=line[1].replace("_",",");
				results.push(newResult);
			}
		}
	}
	return results;
}

export async function loadFISHSURVEY(dataItem) {
	const rawData = await (await fetch(url)).text();
	const lines  = rawData.split("\n");
	let results = [];
	for (let i=1; i < lines.length;i++) {
		const line = lines[i].split(",");
		if (line[0]==dataItem.identifier && line[1]==dataItem.location.replace(",","_")) {
			for (let z = 2; z <=9;z++) {
				let year = 2015+z;
				let newResult = { x: String(year), y: Number(line[z]) };
				results.push(newResult);
			}
		}
		
	}
	return results;
}