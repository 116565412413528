/* eslint-disable */
import {
    BaseAPI
} from './BaseAPI';

import { loadCSV } from "./applications/CSV.js"
import { loadEPICOLLECT, resolveEPICOLLECT } from "./applications/EPICOLLECT.js"
import { loadFISHSURVEY, resolveFISHSURVEY } from "./applications/FISHSURVEY.js"
import { loadCSV2, resolveCSV2 } from "./applications/CSV2.js"
import { loadWATERQUALITY, resolveWATERQUALITY } from "./applications/WATERQUALITY.js"
import { loadNRW, resolveNRW } from "./applications/NRW.js"
import { loadNRW2, resolveNRW2 } from "./applications/NRW2.js"

export class DataService extends BaseAPI {
    constructor(config) {
        super(config);
    }

    async getDataSets(sampleTypes, dataSources, suitabilityIndicators, samplingApproaches,timeIntervals) {
         const query =
         `
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select * where {
                ?id rdf:type fwgw:DataSet .
                ?id fwgw:hasSampleType ?sampleType .
                ?id fwgw:hasSamplingApproach ?samplingApproach .
                ?id fwgw:hasSuitabilityIndicator ?suitabilityIndicator .
                ?id schema:application ?application .
                ?id fwgw:providedBy ?organisation .
                ?id schema:startDate ?startDate .
                ?id schema:endDate ?endDate .
                ?id dct:title ?title.
                optional { ?id schema:url ?url .}
                optional { ?id dct:identifier ?identifier .}
                optional { ?id schema:unitText ?unitText .}
                optional { ?id schema:location ?location .}
            }

         `;
         var responses = await this.query(query);
         let newResponses = [];
         var epiCollectResponses = [];
         var fishSurveyResponses = [];
         var csv2Responses = [];
         var waterqualityResponses = [];
         var nrwResponses = [];
         responses = responses.concat( await resolveNRW2());
         for (const response of responses) {
            if (!samplingApproaches.includes(response.samplingApproach)) continue;
            if (!dataSources.includes(response.organisation)) continue;
            if (!suitabilityIndicators.includes(response.suitabilityIndicator)) continue;
            if (!sampleTypes.includes(response.sampleType)) continue;
            if (response.application.endsWith("EPICOLLECT")) epiCollectResponses.push(response);
            else if (response.application.endsWith("FISHSURVEY")) fishSurveyResponses.push(response);
            else if (response.application.endsWith("CSV2")) csv2Responses.push(response);
            else if (response.application.endsWith("WATERQUALITY")) waterqualityResponses.push(response);
            else if (response.application.endsWith("NRW")) nrwResponses.push(response);
            else newResponses.push(response);
         }  
         if (epiCollectResponses.length > 0) epiCollectResponses = await resolveEPICOLLECT(epiCollectResponses);
         newResponses= newResponses.concat(epiCollectResponses);
         if (fishSurveyResponses.length > 0) fishSurveyResponses = await resolveFISHSURVEY(fishSurveyResponses);
         newResponses= newResponses.concat(fishSurveyResponses);
         if (csv2Responses.length > 0) csv2Responses = await resolveCSV2(csv2Responses);
         newResponses= newResponses.concat(csv2Responses);
         if (waterqualityResponses.length > 0) waterqualityResponses = await resolveWATERQUALITY(waterqualityResponses);
         newResponses= newResponses.concat(waterqualityResponses);
         if (nrwResponses.length > 0) nrwResponses = await resolveNRW(nrwResponses);
         newResponses= newResponses.concat(nrwResponses);
        return newResponses;
    }

    async getData(dataSet) {
        if (dataSet.application.endsWith("CSV")) return loadCSV(dataSet);
        if (dataSet.application.endsWith("EPICOLLECT")) return loadEPICOLLECT(dataSet);
        if (dataSet.application.endsWith("FISHSURVEY")) return loadFISHSURVEY(dataSet);
        if (dataSet.application.endsWith("CSV2")) return loadCSV2(dataSet);
        if (dataSet.application.endsWith("WATERQUALITY")) return loadWATERQUALITY(dataSet);
        if (dataSet.application.endsWith("NRW")) return loadNRW(dataSet);
        if (dataSet.application.endsWith("NRW2")) return loadNRW2(dataSet);
    }
};


