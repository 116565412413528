<template>
  <div class="graph-view">
    <FilteringBar />
    <div class="graph-container">
      <div class="header-section">
        <h1>Data Analysis</h1>
        <div v-if="graphs.length === 0" class="no-graphs-message">
          <v-alert type="info" variant="tonal">
            No graphs to display. Select data points from the map view to visualize them here.
          </v-alert>
        </div>
      </div>

       <!-- Disclaimers -->
      <MergeDisclaimer
        v-if="mergedDatasets.length >= 2"
        :dataset-names="mergedDatasets"
        @closeDisclaimer="closeMergeDisclaimer"
      />
      <DatasetDisclaimer
        v-else-if="currentDataset && disclaimerVisible"
        :dataset-name="currentDataset"
        @closeDisclaimer="closeDisclaimer"
      />

      <div class="graphs-grid">
        <template v-for="(graph, index) in graphs" :key="index">
          <LineGraph
            :title="graph[0].name"
            :unit="graph[0].unit"
            :graphNo="index"
            :noGraphs="graphs.length"
            :series="graph"
            :allCharts="graphs"
            @closeChart="closeGraph"
            @mergeCharts="mergeCharts"
            @enableScroll="enableScroll"
            @disableScroll="disableScroll"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from 'vue'
import FilteringBar from "./FilteringBar.vue"
import LineGraph from "./LineGraph.vue"
import DatasetDisclaimer from "./DatasetDisclaimer.vue"
import MergeDisclaimer from "./MergeDisclaimer.vue"
import useEmitter from '@/plugins/useEmitter'

const emitter = useEmitter()

defineProps({
  showCatchments: Boolean,
  showRivers: Boolean,
  showSubCatchments: Boolean,
  showMonitoringPoints: Boolean
})

// Reactive state - original states
const graphs = ref([])
const scrollEnabled = ref(true)
const currentDataset = ref(null)
const disclaimerVisible = ref(true)

// Added states for merge disclaimer
const mergedDatasets = ref([])
const mergeDisclaimerVisible = ref(true)

// Event listeners for dataset updates
emitter.on("datasetInfo", (datasetName) => {
  console.log("Received dataset name:", datasetName)
  currentDataset.value = datasetName
  disclaimerVisible.value = true

  // Only track dataset if we're not already showing merged disclaimer
  if (mergedDatasets.value.length < 2) {
    // Add to merged datasets if not already present
    if (!mergedDatasets.value.includes(datasetName)) {
      mergedDatasets.value.push(datasetName)
    }
  }
})

emitter.on("goGraphs", (value) => {
  if (Array.isArray(value)) {
    graphs.value = value
  } else {
    console.warn("Received invalid graphs data:", value)
  }
})

// Watch for graphs changes
watch(graphs, () => {
  emitter.emit("changeGraph", graphs.value)
  if (graphs.value.length === 0) {
    currentDataset.value = null
    mergedDatasets.value = [] // Clear merged datasets when no graphs
  }
}, { deep: true})

watch(mergedDatasets, (newValue) => {
  console.log("Merged datasets updated:", newValue)
  if (newValue.length >= 2) {
    // Hide regular disclaimer when showing merge disclaimer
    disclaimerVisible.value = false
  }
}, { deep: true, immediate: true })

// Event handlers
const closeGraph = (index) => {
  console.log("Closing graph at index:", index)
  graphs.value.splice(index, 1)
  if (graphs.value.length === 0) {
    currentDataset.value = null
    mergedDatasets.value = []
    disclaimerVisible.value = true
    mergeDisclaimerVisible.value = true
  } else if (graphs.value.length === 1) {
    // If only one graph remains, switch back to regular disclaimer
    mergedDatasets.value = []
    disclaimerVisible.value = true
  }
}

const closeDisclaimer = () => {
  disclaimerVisible.value = false
}

// Added handler for merge disclaimer
const closeMergeDisclaimer = () => {
  mergeDisclaimerVisible.value = false
}

const mergeCharts = (sourceIndex, targetIndex) => {
  const sourceChart = graphs.value[sourceIndex]
  const targetChart = graphs.value[targetIndex]

  // Extract dataset names from the charts being merged
  const sourceName = extractDatasetName(sourceChart[0].name)
  const targetName = extractDatasetName(targetChart[0].name)

  // Add both datasets to mergedDatasets if not already present
  if (!mergedDatasets.value.includes(sourceName)) {
    mergedDatasets.value.push(sourceName)
  }
  if (!mergedDatasets.value.includes(targetName)) {
    mergedDatasets.value.push(targetName)
  }

  // Merge the data series
  const mergedSeries = [...sourceChart, ...targetChart]
  mergedSeries[0].name = `${sourceChart[0].name} and ${targetChart[0].name}`

  // Update the source chart and remove target
  graphs.value[sourceIndex] = mergedSeries
  graphs.value.splice(targetIndex, 1)

  // Hide regular disclaimer and show merge disclaimer
  disclaimerVisible.value = false
  mergeDisclaimerVisible.value = true
}

// Helper function to extract dataset name from graph name
const extractDatasetName = (graphName) => {
  // Assuming graph name is in format "DatasetName(location)" or "DatasetName and DatasetName"
  let name = graphName.split('(')[0]  // Remove location part if present
  name = name.split(' and ')[0]       // Take first dataset name if merged
  return name.trim()
}

const enableScroll = () => {
  scrollEnabled.value = true
}

const disableScroll = () => {
  scrollEnabled.value = false
}

onMounted(() => {
  console.log("GraphView mounted")
})
</script>

<style scoped>
.graph-view {
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa;
}

.graph-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.header-section {
  margin-bottom: 24px;
}

.header-section h1 {
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 16px;
}

.no-graphs-message {
  max-width: 600px;
  margin: 40px auto;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.graphs-grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Scrollbar styling */
.graph-container::-webkit-scrollbar {
  width: 12px;
}

/* Transitions */
.graphs-grid > * {
  transition: all 0.3s ease;
}

.graph-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

.graph-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

.graph-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox scrollbar */
.graph-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .graph-container {
    padding: 12px;
  }

  .header-section h1 {
    font-size: 20px;
  }

  .graphs-grid {
    gap: 16px;
  }

  .no-graphs-message {
    margin: 20px auto;
    padding: 16px;
  }
}

/* Print styles */
@media print {
  .graph-view {
    height: auto;
  }

  .graph-container {
    overflow: visible;
  }

  .no-graphs-message {
    box-shadow: none;
    border: 1px solid #ddd;
  }
}
</style>