import { DateTime } from "luxon";

export async function resolveWATERQUALITY(dataItems) {
	const rawData = await (await fetch(dataItems[0].url)).text();
	const lines  = rawData.split("\n");
	let results = [];
	let doneDataItems = [];	
	for (const dataItem of dataItems) {
		for (let i=1; i < lines.length;i++) {
			const line = lines[i].split(",");
			if (line[3]!=dataItem.identifier) continue;
			const location = line[1]+","+line[2];
			if (doneDataItems.includes(location+"_"+dataItem.identifier)) continue;
			doneDataItems.push(location+"_"+dataItem.identifier);
			let newResult = { ...dataItem };
			newResult.id=newResult.id+"_"+i;
			newResult.location=location;
			results.push(newResult);
		}
	}
	return results;
}

export async function loadWATERQUALITY(dataItem) {
	const rawData = await (await fetch(dataItem.url)).text();
	const lines  = rawData.split("\n");
	let results = [];
	for (let i=1; i < lines.length;i++) {
		const line = lines[i].split(",");
		const location = line[1]+","+line[2];
		if (location==dataItem.location && line[3]==dataItem.identifier) {
			if (line[0].includes(":")) {
				const dateTime = DateTime.fromFormat(line[0],"dd/MM/yyyy HH:mm");
				if (dateTime.isValid) line[0]=dateTime.toISO();
				else console.log(dateTime)
			}
			let newResult = { x: line[0], y: Number(line[4]) };
			results.push(newResult);	
		}
		
	}
	return results;
}