<template>
  <div class="map-container">
    <div class="search-overlay">
      <div class="search-container" ref="searchContainerRef">
        <div class="search-icon">
          <v-icon>mdi-magnify</v-icon>
        </div>
        <input
          type="text"
          class="search-input"
          placeholder="Search for locations in Wales"
          v-model="searchQuery"
          @input="handleSearch"
          @focus="showSuggestions = true"
          @keydown.down.prevent="navigateSuggestion('down')"
          @keydown.up.prevent="navigateSuggestion('up')"
          @keydown.enter.prevent="selectHighlightedSuggestion"
        >
        <div class="toggle-container">
          <label class="switch">
            <input type="checkbox" v-model="includeGeocoded">
            <span class="slider round"></span>
          </label>
          <span class="toggle-label">Include geocoded</span>
        </div>
        <div v-if="isLoading" class="loading-indicator">Loading...</div>
        <div v-if="showSuggestions" class="suggestions-container" ref="suggestionsContainer">
          <button class="close-button" @click="closeSuggestions">
            <v-icon>mdi-close</v-icon>
          </button>
          <div class="suggestions-scroll">
            <template v-if="filteredSuggestions.length">
              <div class="suggestion-category">
                <div class="category-header" @click="toggleLocalResults($event)">
                  <v-icon>{{ localExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  Freshwater Results ({{ localSuggestions.length }})
                </div>
                <div v-show="localExpanded" class="category-content">
                  <div
                    v-for="(suggestion, index) in localSuggestions"
                    :key="'fresh'+suggestion.id"
                    class="suggestion-item"
                    :class="{ 'highlighted': index === highlightedIndex }"
                    @mousedown.prevent="selectSuggestion(suggestion)"
                    @mouseover="highlightedIndex = index"
                  >
                    <v-icon small class="suggestion-icon">mdi-map-marker</v-icon>
                    {{ suggestion.title }}
                  </div>
                </div>
              </div>
              <div v-if="geocodedSuggestions.length && includeGeocoded" class="suggestion-category">
                <div class="category-header" @click="toggleGeocodedResults($event)">
                  <v-icon>{{ geocodedExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  Map Results ({{ geocodedSuggestions.length }})
                </div>
                <div v-show="geocodedExpanded" class="category-content">
                  <div
                    v-for="(suggestion, index) in geocodedSuggestions"
                    :key="'geo'+suggestion.id"
                    class="suggestion-item"
                    :class="{ 'highlighted': index + localSuggestions.length === highlightedIndex }"
                    @mousedown.prevent="selectSuggestion(suggestion)"
                    @mouseover="showTooltip(suggestion, $event)"
                    @mouseout="hideTooltip"
                  >
                    <v-icon small class="suggestion-icon">mdi-earth</v-icon>
                    {{ suggestion.title }}
                  </div>
                </div>
              </div>
            </template>
            <div v-else-if="!isLoading" class="no-results">
              No results found
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="loader" v-if="loaderVisible"></div>
    <div id="map" class="map"></div>
  </div>
  <div v-if="tooltipVisible" class="custom-tooltip" :style="tooltipStyle">
    {{ tooltipContent }}
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ref, computed, onMounted, onUnmounted, watch, inject, nextTick } from 'vue';
import useEmitter from '@/plugins/useEmitter'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name: 'MapComponent',
  props: {
    showCatchments: Boolean,
    showRivers: Boolean,
    showSubCatchments: Boolean,
    showMonitoringPoints: Boolean
  },
  setup(props, { emit }) {
    const loaderVisible = ref(false);
    const GISService = inject('GISService');
    const map = ref(null);
    const mapLoaded = ref(false);
    const searchQuery = ref('');
    const showSuggestions = ref(false);
    const filteredSuggestions = ref([]);
    const allFeatures = ref([]);
    const emitter = useEmitter();
    const mapStyle = ref('mapbox://styles/mapbox/light-v10');
    const isLoading = ref(false);
    const includeGeocoded = ref(true);
    const highlightedIndex = ref(-1);
    const localSuggestions = computed(() =>
      filteredSuggestions.value.filter(s => s.type !== 'geocoded')
    );
    const geocodedSuggestions = computed(() =>
      includeGeocoded.value ? filteredSuggestions.value.filter(s => s.type === 'geocoded') : []
    );
    const localExpanded = ref(true);
    const geocodedExpanded = ref(true);
    const suggestionsContainer = ref(null);
    const suggestionsHeight = ref(0);
    const searchContainerRef = ref(null);

    const toggleLocalResults = (event) => {
      event.stopPropagation();
      localExpanded.value = !localExpanded.value;
      nextTick(() => {
        updateSuggestionsHeight();
      });
    };

    const toggleGeocodedResults = (event) => {
      event.stopPropagation();
      geocodedExpanded.value = !geocodedExpanded.value;
      nextTick(() => {
        updateSuggestionsHeight();
      });
    };

    const updateSuggestionsHeight = () => {
      if (suggestionsContainer.value) {
        const currentHeight = suggestionsContainer.value.scrollHeight;
        suggestionsHeight.value = Math.max(suggestionsHeight.value, currentHeight);
      }
    };

    watch([filteredSuggestions, showSuggestions], () => {
      nextTick(() => {
        updateSuggestionsHeight();
      });
    });

    const tooltipVisible = ref(false);
    const tooltipContent = ref('');
    const tooltipStyle = ref({
      position: 'fixed',
      top: '0px',
      left: '0px',
    });

    const showTooltip = (suggestion, event) => {
      tooltipContent.value = suggestion.fullPlaceName;
      tooltipVisible.value = true;

      // Position the tooltip near the mouse cursor
      tooltipStyle.value = {
        position: 'fixed',
        top: `${event.clientY + 10}px`,
        left: `${event.clientX + 10}px`,
      };
    };

    const hideTooltip = () => {
      tooltipVisible.value = false;
    };

    // Start defining the map and datasets

    const WALES_CENTER = [-3.7837, 52.1307];
    const DEFAULT_ZOOM = 7;

    const colorMap = (organisation) => {
      if (organisation === "DCWW") return 'blue';
      if (organisation === "NRW") return 'green';
      if (organisation === "GWT") return 'yellow';
      if (organisation === "WUF") return 'orange';
      return 'black';
    }

    const toggleMapStyle = () => {
      if (!map.value) return;

      const currentStyle = mapStyle.value;
      const newStyle = currentStyle === 'mapbox://styles/mapbox/light-v10'
        ? 'mapbox://styles/mapbox/satellite-v9'
        : 'mapbox://styles/mapbox/light-v10';

      const center = map.value.getCenter();
      const zoom = map.value.getZoom();

      const layerStates = {
        catchments: map.value.getLayoutProperty('catchments-layer', 'visibility') === 'visible',
        rivers: map.value.getLayoutProperty('rivers-layer', 'visibility') === 'visible',
        subCatchments: map.value.getLayoutProperty('sub-catchments-layer', 'visibility') === 'visible',
        monitoringPoints: map.value.getLayoutProperty('monitoringpoints-layer', 'visibility') === 'visible'
      };

      map.value.setStyle(newStyle);
      mapStyle.value = newStyle;

      map.value.once('styledata', () => {
        map.value.setCenter(center);
        map.value.setZoom(zoom);

        if (layerStates.catchments) {
          updateSource('catchments', true);
          toggleLayerVisibility('catchments-layer', true);
          toggleLayerVisibility('catchments-outline', true);
        }
        if (layerStates.rivers) {
          updateSource('rivers', true);
          toggleLayerVisibility('rivers-layer', true);
        }
        if (layerStates.subCatchments) {
          updateSource('sub-catchments', true);
          toggleLayerVisibility('sub-catchments-layer', true);
          toggleLayerVisibility('sub-catchments-outline', true);
        }
        if (layerStates.monitoringPoints) {
          toggleLayerVisibility('monitoringpoints-layer', true);
        }

        addTooltips();
      });
    };

    const closeSuggestions = () => {
      showSuggestions.value = false;
    };

    const handleOutsideClick = (event) => {
      if (
        searchContainerRef.value &&
        !searchContainerRef.value.contains(event.target) &&
        !event.target.closest('.close-button')
      ) {
        closeSuggestions();
      }
    };

    const removeMonitoringPoints = (key) => {
      try {
        if (map.value.getLayer('monitoringpoints-layer-'+key)) {
          map.value.removeLayer('monitoringpoints-layer-'+key);
        }
        if (map.value.getSource('monitoringpoints-'+key)) {
          map.value.removeSource('monitoringpoints-'+key);
        }
      } catch (e) {
        console.warn(`Error removing monitoring points for ${key}:`, e);
      }
    };

    onMounted(() => {
      initializeMap();
      emitter.emit("mapmoved", map.value.getBounds());
      loadFeatures();
      document.addEventListener('click', handleOutsideClick);
    });

    onUnmounted(() => {
      if (map.value) {
        map.value.remove();
      }
      document.addEventListener('click', handleOutsideClick);
    });

    const initializeMap = () => {
      console.log('Initializing map');
      mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmVpaDE5NCIsImEiOiJjbGdob25kbDAwbnB6M2l0MWpxNnBvczFvIn0.Z8UWHTSl7KxL56wrT4APXQ';

      if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
        mapboxgl.setRTLTextPlugin(
          'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
          null,
          true
        );
      }

      map.value = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v10',
        center: WALES_CENTER,
        zoom: DEFAULT_ZOOM,
      });

      map.value.on('moveend', () => {
        emitter.emit("mapmoved", map.value.getBounds());
      });

      map.value.addControl(new mapboxgl.NavigationControl(), 'top-left');

      class StyleToggleControl {
        onAdd(map) {
          this._map = map;
          this._container = document.createElement('div');
          this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
          this._container.innerHTML = '<button class="mapboxgl-ctrl-icon" type="button" title="Toggle map style"><span class="mapboxgl-ctrl-icon" aria-hidden="true">🛰️</span></button>';
          this._container.onclick = toggleMapStyle;
          return this._container;
        }

        onRemove() {
          this._container.parentNode.removeChild(this._container);
          this._map = undefined;
        }
      }

      map.value.addControl(new StyleToggleControl(), 'top-left');

      map.value.on('load', () => {
        mapLoaded.value = true;
        updateSource('catchments', true);
        toggleLayerVisibility('catchments-layer', true);
        toggleLayerVisibility('catchments-outline', true);

        addTooltips();
      });

      map.value.on('error', (e) => {
        console.error('Mapbox GL error:', e);
      });
    };

    const addLayer = (name, type, color, visible) => {
      if (!map.value.getSource(name)) {
        map.value.addSource(name, {
          type: 'geojson',
          dynamic: true,
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        if (type === 'fill') {
          map.value.addLayer({
            id: `${name}-layer`,
            type: 'fill',
            source: name,
            paint: {
              'fill-color': color,
              'fill-opacity': 0.3,
            },
            layout: {
              visibility: visible ? 'visible' : 'none',
            },
          });

          map.value.addLayer({
            id: `${name}-outline`,
            type: 'line',
            source: name,
            paint: {
              'line-color': color,
              'line-width': 1,
            },
            layout: {
              visibility: visible ? 'visible' : 'none',
            },
          });
        } else if (type === 'line') {
          map.value.addLayer({
            id: `${name}-layer`,
            type: 'line',
            source: name,
            paint: {
              'line-color': color,
              'line-width': 2,
            },
            layout: {
              visibility: visible ? 'visible' : 'none',
            },
          });
        }
      }
    };

    const renderFeature = (item, id, sourceName, geometryType) => {
      let feature = null;
      try {
        let coordinates;
        if (geometryType === 'Polygon') {
          coordinates = [item.geo];
          if (coordinates[0][0][0] !== coordinates[0][coordinates[0].length - 1][0] ||
              coordinates[0][0][1] !== coordinates[0][coordinates[0].length - 1][1]) {
            coordinates[0].push(coordinates[0][0]);
          }
        } else {
          coordinates = item.geo;
        }
        feature = {
          id: id,
          type: 'Feature',
          properties: { title: item.title },
          geometry: {
            type: geometryType,
            coordinates: coordinates
          }
        };

      } catch (error) {
        console.error(`Error processing item: ${item.title}`, error);
        return null;
      }

      if (feature == null) return;
      try {
        map.value.getSource(sourceName).updateData({
          type: 'FeatureCollection',
          features: [feature]
        });
      } catch (error) {
        console.error(`Error updating source ${sourceName}:`, error);
      }
    }

    const updateSource = (sourceName, toShow) => {
      if (!mapLoaded.value || !map.value) {
        console.warn(`Source ${sourceName} not ready or map not loaded`);
        return;
      }

      if (toShow == true && !map.value.getSource(sourceName)) {
        if (sourceName == 'catchments') {
          addLayer('catchments', 'fill', '#088', props.showCatchments);
          GISService.getCatchments((item, id) => renderFeature(item, id, 'catchments', 'Polygon'));
        } else if (sourceName == 'rivers') {
          addLayer('rivers', 'line', '#4CAF50', props.showRivers);
          GISService.getRivers((item, id) => renderFeature(item, id, 'rivers', 'MultiLineString'));
        } else if (sourceName == 'sub-catchments') {
          addLayer('sub-catchments', 'fill', '#FF9800', props.showSubCatchments);
          GISService.getSubCatchments((item, id) => renderFeature(item, id, 'sub-catchments', 'Polygon'));
        }

        console.log(`Updating source: ${sourceName}`);
      }
    };

    const addTooltips = () => {
      const addTooltipToLayer = (layerId) => {
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        map.value.on('mousemove', layerId, (e) => {
          if (!e.features || e.features.length === 0) return;

          map.value.getCanvas().style.cursor = 'pointer';
          const feature = e.features[0];
          const title = feature.properties.title;

          let coordinates;
          if (feature.geometry.type === 'Polygon') {
            coordinates = feature.geometry.coordinates[0][0];
          } else if (feature.geometry.type === 'MultiLineString') {
            coordinates = feature.geometry.coordinates[0][0];
          } else if (feature.geometry.type === 'LineString') {
            coordinates = feature.geometry.coordinates[0];
          } else {
            coordinates = feature.geometry.coordinates;
          }

          if (Array.isArray(coordinates) && coordinates.length === 2 &&
              typeof coordinates[0] === 'number' && typeof coordinates[1] === 'number') {

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            popup.setLngLat(coordinates).setHTML(title.replace("-", " ")).addTo(map.value);
          }
        });

        map.value.on('mouseleave', layerId, () => {
          map.value.getCanvas().style.cursor = '';
          popup.remove();
        });
      };

      addTooltipToLayer('catchments-layer');
      addTooltipToLayer('rivers-layer');
      addTooltipToLayer('sub-catchments-layer');
    };

    watch(() => props.showCatchments, (show) => {
      updateSource('catchments', show);
      toggleLayerVisibility('catchments-layer', show);
      toggleLayerVisibility('catchments-outline', show);
    });

    watch(() => props.showMonitoringPoints, (show) => {
      toggleLayerVisibility('monitoringpoints-layer', show);
    });

    watch(() => props.showRivers, (show) => {
      updateSource('rivers', show);
      toggleLayerVisibility('rivers-layer', show);
    });

    watch(() => props.showSubCatchments, (show) => {
      updateSource('sub-catchments', show);
      toggleLayerVisibility('sub-catchments-layer', show);
      toggleLayerVisibility('sub-catchments-outline', show);
    });

    const toggleLayerVisibility = (layerId, isVisible) => {
      if (mapLoaded.value && map.value.getLayer(layerId)) {
        map.value.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
      } else {
        console.warn(`Layer ${layerId} not found or map not loaded`);
      }
    };

    const loadFeatures = () => {
      allFeatures.value = [];
      GISService.getCatchments((item, id) => allFeatures.value.push({...item, id, type: 'catchment'}));
      GISService.getRivers((item, id) => allFeatures.value.push({...item, id, type: 'river'}));
      GISService.getSubCatchments((item, id) => allFeatures.value.push({...item, id, type: 'subCatchment'}));
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };

    const handleSearch = async () => {
      isLoading.value = true;
      highlightedIndex.value = -1;
      let localResults = [];

      if (allFeatures.value && Array.isArray(allFeatures.value)) {
        localResults = allFeatures.value.filter((asset) =>
          asset.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      } else {
        console.warn('allFeatures.value is undefined or not an array');
      }

      let geocodingResults = [];
      if (includeGeocoded.value) {
        const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchQuery.value)}.json?access_token=${mapboxgl.accessToken}&country=GB&bbox=-5.5,51.3,-2.8,53.4`;

        try {
          const response = await fetch(geocodingUrl);
          const data = await response.json();

          geocodingResults = data.features
            .filter(feature =>
              feature.context && feature.context.some(context => context.id.startsWith('region') && context.text === 'Wales')
            )
            .map(feature => {
              // Parse the place_name to extract only the location name
              let title = feature.place_name.split(',')[0].trim();

              // If the title is just a postcode, use the next part (if available)
              if (/^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/.test(title) && feature.place_name.split(',').length > 1) {
                title = feature.place_name.split(',')[1].trim();
              }

              return {
                title: title,
                fullPlaceName: feature.place_name,
                id: feature.id,
                type: 'geocoded',
                geo: feature.geometry.coordinates
              };
            });
        } catch (error) {
          console.error('Error fetching geocoding results:', error);
        }
      }

      filteredSuggestions.value = [...localResults, ...geocodingResults];
      isLoading.value = false;

      // Automatically expand results when new search is performed
      localExpanded.value = true;
      geocodedExpanded.value = true;
    };

    const navigateSuggestion = (direction) => {
      const maxIndex = filteredSuggestions.value.length - 1;
      if (direction === 'down') {
        highlightedIndex.value = highlightedIndex.value < maxIndex ? highlightedIndex.value + 1 : 0;
      } else {
        highlightedIndex.value = highlightedIndex.value > 0 ? highlightedIndex.value - 1 : maxIndex;
      }
    };

    const selectHighlightedSuggestion = () => {
      if (highlightedIndex.value >= 0 && highlightedIndex.value < filteredSuggestions.value.length) {
        selectSuggestion(filteredSuggestions.value[highlightedIndex.value]);
      }
    };

    watch(includeGeocoded, () => {
      handleSearch();
    });

    const debouncedHandleSearch = debounce(handleSearch, 300);

    watch(searchQuery, () => {
      debouncedHandleSearch();
    });

    const selectSuggestion = (suggestion) => {
      searchQuery.value = suggestion.title;
      showSuggestions.value = false;
      focusOnShape(suggestion);
    };

    const focusOnShape = (suggestion) => {
      if (mapLoaded.value) {
        let coordinates = suggestion.geo;
        if (suggestion.type === 'geocoded') {
          map.value.flyTo({
            center: coordinates,
            zoom: 15
          });
        } else {
          if (coordinates.length == 1) coordinates = coordinates[0];
          const bounds = coordinates.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
          );
          map.value.fitBounds(bounds, { padding: 50 });
        }
      }
    };

    const hideSuggestionsDelayed = () => {
      setTimeout(() => {
        showSuggestions.value = false;
      }, 200);
    };

    const handleEnter = () => {
      if (filteredSuggestions.value.length > 0) {
        emit('filteredResults', filteredSuggestions.value);
      }
    };

    emitter.on("goHome", (coord) => {
      if (coord != undefined) {
        setTimeout(function() {
          map.value.fitBounds([{lng:coord._sw.lng,lat:coord._sw.lat},{lng:coord._ne.lng,lat:coord._ne.lat}]);
        });
      }
    });

    emitter.on("zoomTo", async (gps) => {
      let coords = gps.split(',');
      coords[0] = Number(coords[0]);
      coords[1] = Number(coords[1]);
      map.value.flyTo({center: coords.reverse(), zoom:15});
    });

    emitter.on("addmonitoringpoints", async ([key, values]) => {
      try {
        if (map.value.getLayer('monitoringpoints-layer-'+key) != undefined) {
          map.value.removeLayer('monitoringpoints-layer-'+key);
        }
        if (map.value.getSource('monitoringpoints-'+key) != undefined) {
          map.value.removeSource('monitoringpoints-'+key);
        }
      } catch (e) {
        console.log(e);
      }

      let sourceData = {
        "type": "FeatureCollection",
        "features": []
      };

      for (const value of values) {
        try {
          if (!value.location) {
            console.warn(`Skipping monitoring point without location in ${key}`);
            continue;
          }

          const coordinates = value.location.split(",")
            .map(x => Number(x.trim()))
            .reverse();

          // Validate coordinates
          if (coordinates.length !== 2 ||
              isNaN(coordinates[0]) ||
              isNaN(coordinates[1])) {
            console.warn(`Invalid coordinates for monitoring point in ${key}:`, value.location);
            continue;
          }

          sourceData.features.push({
            "type": "Feature",
            "properties": {"stimulus": value.stimulus},
            "geometry": {
              "type": "Point",
              "coordinates": coordinates
            }
          });
        } catch (e) {
          console.warn(`Error processing monitoring point in ${key}:`, e);
        }
      }

      if (sourceData.features.length === 0) {
        console.warn(`No valid monitoring points to display for ${key}`);
        return;
      }

      map.value.addSource('monitoringpoints-'+key, {
        type: 'geojson',
        data: sourceData
      });

      map.value.addLayer({
        'id': 'monitoringpoints-layer-'+key,
        'type': 'circle',
        'source': 'monitoringpoints-'+key,
        'paint': {
          'circle-radius': 4,
          'circle-stroke-width': 2,
          'circle-color': colorMap(key),
          'circle-stroke-color': 'white'
        }
      });

      map.value.on('click', 'monitoringpoints-layer-'+key, function (e) {
        var coordinates = e.features[0].geometry.coordinates;
        try {
          while (coordinates.length!=2) coordinates=coordinates[0]
          var html="<table><tr><th>Monitoring</th</tr>";
          const stims = JSON.parse(e.features[0].properties.stimulus)
          for (let i=0; i <  stims.length;i++) html+="<tr><td>"+(i+1)+". "+stims[i]+"</td></tr>";
          html+="</table>";
          new mapboxgl.Popup().setLngLat(coordinates).setMaxWidth("700px").setHTML(html).addTo(map.value);
          emitter.emit("filterToGPS",coordinates);
        } catch (e) {
          console.log(e);
          console.error("ERROR GETTING COORDS");
        }
      });
    });

    emitter.on("removeMonitoringPoints", (key) => {
      removeMonitoringPoints(key);
    });

    emitter.on("viewgeojson", async (value) => {
      loaderVisible.value = true;
      try {
        if (map.value.getLayer('data-layer-line') != undefined) map.value.removeLayer('data-layer-line');
        if (map.value.getLayer('data-layer') != undefined) map.value.removeLayer('data-layer');
        if (map.value.getSource('data') != undefined) map.value.removeSource('data');
      } catch (e) {console.log(e)}

      var bounds = new mapboxgl.LngLatBounds();
      const response = await (await fetch(value.url)).json();
      console.log("Loaded");

      response.features.forEach(function(feature) {
        let coordinates = feature.geometry.coordinates;
        while (coordinates.length!=2 ||  Array.isArray(coordinates[0]) || Array.isArray(coordinates[1]) ) coordinates=coordinates[0]
        bounds.extend(coordinates);
      });

      map.value.addSource('data', {
        type: 'geojson',
        data: response
      });

      if (value.type.endsWith("AREAGEOJSON")) {
        map.value.addLayer({
          'id': 'data-layer',
          'type': 'fill',
          'source': 'data',
          'paint': {
            "fill-color":'red',
            'fill-opacity': 0.3,
          }
        });

        map.value.addLayer({
          id: `data-layer-line`,
          type: 'line',
          source: 'data',
          paint: {
            'line-color': 'red',
            'line-width': 1,
          }
        });
      } else {
        map.value.addLayer({
          'id': 'data-layer',
          'type': 'circle',
          'source': 'data',
          'paint': {
            'circle-radius': 4,
            'circle-stroke-width': 2,
            'circle-color': 'red',
            'circle-stroke-color': 'white'
          }
        });
      }

      map.value.fitBounds(bounds);
      map.value.on('click', 'data-layer', function (e) {
        var coordinates = e.features[0].geometry.coordinates;
        try {
          while (coordinates.length!=2) coordinates=coordinates[0]
          var html="<table>";
          for (const [key, value] of Object.entries(e.features[0].properties)) {
            html+="<tr><th>"+key+":</th><td>"+value+"</td>";
          }
          html+="</table>";
          new mapboxgl.Popup().setLngLat(coordinates).setMaxWidth("700px").setHTML(html).addTo(map.value);
        } catch (e) {
          console.error("ERROR GETTING COORDS");
        }
      });

      loaderVisible.value = false;
    });

    emitter.on("hidegeojson", () => {
      if (map.value.getLayer('data-layer-line') != undefined) map.value.removeLayer('data-layer-line');
      if (map.value.getLayer('data-layer') != undefined) map.value.removeLayer('data-layer');
      if (map.value.getSource('data') != undefined) map.value.removeSource('data');
    });

    return {
      searchQuery,
      showSuggestions,
      filteredSuggestions,
      handleSearch: debouncedHandleSearch,
      selectSuggestion,
      hideSuggestionsDelayed,
      handleEnter,
      loaderVisible,
      toggleMapStyle,
      mapStyle,
      isLoading,
      includeGeocoded,
      highlightedIndex,
      localSuggestions,
      geocodedSuggestions,
      navigateSuggestion,
      selectHighlightedSuggestion,
      localExpanded,
      geocodedExpanded,
      toggleLocalResults,
      toggleGeocodedResults,
      tooltipVisible,
      tooltipContent,
      tooltipStyle,
      showTooltip,
      hideTooltip,
      suggestionsContainer,
      suggestionsHeight,
      closeSuggestions
    };
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.search-overlay {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.search-container {
  position: relative;
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 60%;
  max-width: 60%;
  height: 64px;
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.search-icon {
  color: #757575;
  margin-right: 12px;
}

.search-input {
  border: none;
  outline: none;
  font-size: 16px;
  flex-grow: 1;
  background-color: transparent;
}

.suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  z-index: 1;
  padding-top: 48px;
}

.suggestions-scroll {
  max-height: 300px;
  overflow-y: auto;
}

.suggestion-category {
  margin-bottom: 10px;
}

.category-header {
  font-weight: bold;
  padding: 8px 16px;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.category-header:hover {
  background-color: #e0e0e0;
}

.category-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.suggestion-item {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 32px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.suggestion-icon {
  margin-right: 8px;
}

.highlighted {
  background-color: #e0e0e0;
}


.map {
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-icon {
  font-size: 20px;
  line-height: 30px;
}

#loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #93E9BE;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  z-index: 2;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #757575;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.toggle-label {
  margin-left: 5px;
  font-size: 12px;
}

.suggestion-item {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 32px;
}

.no-results {
  padding: 8px 16px;
  color: #757575;
  font-style: italic;
}

.custom-tooltip {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  pointer-events: none;
  max-width: 300px;
  word-wrap: break-word;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #000000;
}

</style>