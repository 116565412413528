

const stationsURL = "https://corsproxy.io/?https%3A%2F%2Fapi.naturalresources.wales%2Ftelemetry%2Fapi%2Fstations";
const measuresURL = "https://corsproxy.io/?https%3A%2F%2Fapi.naturalresources.wales%2Ftelemetry%2Fapi%2Fmeasures"
const dataURL= "https://corsproxy.io/?https%3A%2F%2Fapi.naturalresources.wales%2Ftelemetry%2Fapi%2Freadings%3FmeasureId%3D"

export async function resolveNRW2() {
	const nrwStations = await (await fetch(stationsURL)).json()
	const nrwMeasures = await (await fetch(measuresURL)).json()
	let finalResults = [];
	let x=0;
	for (const measure of nrwMeasures) {
		let newResult = {};
		if (measure.latest_time == undefined || measure.latest_value == undefined) continue;
		const station = nrwStations.find( x => x.monitoring_station_reference==measure.monitoring_station_reference);
		if (station == undefined) continue;
		newResult.location = station.latitude+","+station.longitude;
		if (measure.measure_type_en=="Water Level") newResult.sampleType="https://graphdb.freshwatergateway.org.uk/Waterlevel";
		else if (measure.measure_type_en=="Rainfall") newResult.sampleType="https://graphdb.freshwatergateway.org.uk/Rainfall";
		else newResult.sampleType="https://graphdb.freshwatergateway.org.uk/Water";
		
		newResult.suitabilityIndicator="https://graphdb.freshwatergateway.org.uk/QAEstblishedProtocols";
		newResult.samplingApproach="https://graphdb.freshwatergateway.org.uk/QuantitativeAutomatic";
		newResult.application="https://graphdb.freshwatergateway.org.uk/NRW2";
		newResult.organisation="https://graphdb.freshwatergateway.org.uk/NRW";
		newResult.startDate="2020-01-01";
		newResult.endDate=measure.latest_time.split(" ")[0]
		newResult.title=measure.measure_type_en;
		newResult.unitText=measure.units;
		newResult.measureId=measure.measure_id;
		newResult.id="NRWAPI"+x;
		x++;
		finalResults.push(newResult);
	}
	return finalResults;
}

//to do pass in times here.
export async function loadNRW2(result) {
	const params = encodeURIComponent(result.measureId);
	const nrwData = await (await fetch(dataURL+params)).json();
	let results = [];
	for (const dataItem of nrwData) {
		results.push({x:dataItem.timestamp, y:Number(dataItem.value)})
	}
	return results;

}