<!-- CONTENTS OF FormatOptions.vue below -->

<template>
  <v-card class="format-options-card">
    <div class="section-title">Format</div>
    <v-divider></v-divider>
    <div class="option-grid">
      <template v-for="option in formatOptions" :key="option.id">
        <div class="option-row">
          <v-switch
            v-model="option.enabled"
            @change="toggleOption(option)"
            class="custom-switch"
            inset
            hide-details
            color="success"
          />
          <div
            :class="['option-label', { 'active-label': option.enabled }]"
          >
            {{ option.label }}
          </div>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "FormatOptions",
  data() {
    return {
      formatOptions: [
        { id: 3, label: "Operational WFD Catchments", enabled: true },
        { id: 4, label: "Sub-catchments", enabled: false },
        { id: 2, label: "Rivers", enabled: false },
        { id: 1, label: "Sampling points", enabled: true }
      ],
    };
  },
  methods: {
    toggleOption(option) {
      if (option.label === "Rivers") {
        this.$emit('toggle-format', { type: 'rivers', enabled: option.enabled });
      } else if (option.label === "Sub-catchments") {
        this.$emit('toggle-format', { type: 'subCatchments', enabled: option.enabled });
      } else if (option.label === "Operational WFD Catchments") {
        this.$emit('toggle-format', { type: 'catchments', enabled: option.enabled });
      } else if (option.label =="Sampling points") {
        this.$emit('toggle-format', { type: 'Sampling points', enabled: option.enabled });
      }
    },
  },
};
</script>

<style scoped>
.format-options-card {
  padding: 16px;
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d7e1;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.option-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
  align-items: center;
}

.option-row {
  display: contents;
}

.option-label {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active-label {
  font-weight: bold;
  color: #000;
}

.custom-switch {
  margin-right: 0;
}

/* Custom styles for v-switch */
:deep(.v-input--switch) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.v-input--switch .v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-input--switch__track) {
  opacity: 1 !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__track) {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

:deep(.v-input--switch__thumb) {
  color: white !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__thumb) {
  transform: translate(16px, 0);
}
</style>