<template>
  <v-card class="time-intervals-card pa-4">
    <h3 class="text-h6 mb-4">Time Intervals</h3>
    <v-container class="pa-0">
      <v-row v-for="(interval, index) in intervals" :key="index" class="mb-4 align-center">
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="interval.startDate"
            label="Start Date"
            readonly
            variant="outlined"
            density="comfortable"
            @click="(e) => openDatePicker(e, interval, 'start')"
            prepend-inner-icon="mdi-calendar"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex align-center justify-center my-2">
          <span class="text-body-2">to</span>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="interval.endDate"
            label="End Date"
            readonly
            variant="outlined"
            density="comfortable"
            @click="(e) => openDatePicker(e, interval, 'end')"
            prepend-inner-icon="mdi-calendar"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" class="d-flex justify-center align-center">
          <v-btn
            icon="mdi-close"
            size="small"
            color="error"
            variant="text"
            @click="removeInterval(index)"
            :disabled="intervals.length === 1"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      color="primary"
      @click="addInterval"
      class="mt-4"
      prepend-icon="mdi-plus"
      variant="tonal"
    >
      Add Interval
    </v-btn>

    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
      :activator="datePickerActivator"
    >
      <v-date-picker
        v-model="tempDate"
        @update:model-value="selectDate"
      ></v-date-picker>
    </v-menu>
  </v-card>
</template>

<script setup>
import { ref, watch } from 'vue';
import useEmitter from '@/plugins/useEmitter'

const emitter = useEmitter()
const intervals = ref([{ startDate: null, endDate: null }]);
const showDatePicker = ref(false);
const tempDate = ref(null);
const activeInterval = ref(null);
const activeType = ref(null);
const datePickerActivator = ref(null);

const addInterval = () => {
  intervals.value.push({ startDate: null, endDate: null });
};

const removeInterval = (index) => {
  if (intervals.value.length > 1) {
    intervals.value.splice(index, 1);
  }
};

const openDatePicker = (event, interval, type) => {
  datePickerActivator.value = event.target;
  activeInterval.value = interval;
  activeType.value = type;
  tempDate.value = interval[type === 'start' ? 'startDate' : 'endDate'];
  showDatePicker.value = true;
};

const selectDate = (date) => {
  if (activeInterval.value && activeType.value) {
    if (activeType.value === 'start') {
      activeInterval.value.startDate = date;
    } else {
      activeInterval.value.endDate = date;
    }
    showDatePicker.value = false;
  }
};

watch(intervals, (newIntervals) => {
  let finalIntervals = newIntervals
    .filter(interval => interval.startDate && interval.endDate)
    .map(interval => ({
      startDate: interval.startDate,
      endDate: interval.endDate
    }))
    .filter(interval => new Date(interval.startDate) < new Date(interval.endDate));

  emitter.emit('timeinterval', finalIntervals);
}, { deep: true });
</script>

<style scoped>
.time-intervals-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
</style>