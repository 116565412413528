/* eslint-disable */
import axios from 'axios';

export class BaseAPI {
  constructor() {
  }

  async query(query) {
    var response = axios.get('https://graphdb.freshwatergateway.org.uk/repositories/freshwatergateway?query='+encodeURIComponent(query), {
      headers: { 'ACCEPT': 'application/sparql-results+json'}
    });
    var data = (await response).data.results.bindings;
    for (let i=0; i < data.length;i++) {
      for (var key in data[i]) {
        if (key=="geo") {
          const split1=data[i][key].value.split(" ");
          for (let x=0; x < split1.length;x++) split1[x]=split1[x].split(",").map(x => Number(x));
          data[i][key] = split1;
        } else {
          data[i][key]=data[i][key].value;
        }
      }
    }
    return data;
  }

  async queryCallback(query,callback) {
    let j=0;
    while (true) {
      let realQuery = query +"LIMIT 100 OFFSET "+(j*100);
      j++;
      var response = axios.get('https://graphdb.freshwatergateway.org.uk/repositories/freshwatergateway?query='+encodeURIComponent(realQuery), {
        headers: { 'ACCEPT': 'application/sparql-results+json'}
      });
      var data = (await response).data.results.bindings;
      for (let i=0; i < data.length;i++) {
        for (var key in data[i]) {
          if (key=="geo") {
            if (data[i][key].value.startsWith("[")) {
              let dataset= JSON.parse(data[i][key].value);
              data[i][key] = dataset;
            } else {
              const split1=data[i][key].value.split(" ");
              for (let x=0; x < split1.length;x++) split1[x]=split1[x].split(",").map(x => Number(x));
              let split2 = [];
              for (let x=0; x < split1.length;x+=4) split2[x/4]=split1[x];
              data[i][key] = split2;
            }
          } else {
            data[i][key]=data[i][key].value;
          }
        }
        callback(data[i],(j*100)+i);
      }
      if (data.length < 100) break;
    }
  }
}