<script setup>
/* eslint-disable */
import { provide,ref } from 'vue';
import {ParameterService} from './api/';
import {GISService} from './api/';
import {DataService} from './api/';
import HeaderBar from './components/HeaderBar.vue'
import SearchResults from './components/SearchResults.vue'
import LeftAppSidebar from './components/LeftAppSidebar.vue'

provide('ParameterService',new ParameterService());
provide('DataService',new DataService());
provide('GISService',new GISService());

const showCatchments = ref(true);
const showRivers = ref(false);
const showSubCatchments = ref(false);
const showMonitoringPoints = ref(true);

const handleFormatToggle = ({ type, enabled }) => {
      if (type === 'rivers') {
        showRivers.value = enabled;
      } else if (type === 'subCatchments') {
        showSubCatchments.value = enabled;
      } else if (type === 'catchments') {
        showCatchments.value = enabled;
      } else if (type=="Sampling points") {
        showMonitoringPoints.value = enabled;
      }
      console.log(`Toggled ${type} to ${enabled}`);
    };


</script>

<template>
  <v-app>
    <HeaderBar />
    <v-main>
      <v-container fluid class="pa-0 ma-0 main-container">
        <v-row no-gutters class="fill-height">
          <v-col cols="3" class="left-sidebar">
            <v-sheet rounded="lg" min-height="268" class="fill-height">
              <LeftAppSidebar @toggle-format="handleFormatToggle"/>
            </v-sheet>
          </v-col>
          <v-col cols="9" class="map-container">
            <router-view :showCatchments="showCatchments" :showRivers="showRivers" :showSubCatchments="showSubCatchments" :showMonitoringPoints="showMonitoringPoints"/>
            <SearchResults/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped>
.main-container {
  height: calc(100vh - 64px); /* Adjust if your HeaderBar height is different */
}

.fill-height {
  height: 100%;
}

.left-sidebar {
  height: 100%;
  overflow-y: auto; /* Make the sidebar scrollable */
}

.left-sidebar-content {
  height: 100%;
}

.map-container {
  position: relative;
  height: 100%;
  overflow: hidden; /* Prevent any potential overflow */
}

.search-results-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 35%; /* Equivalent to 3 columns in a 9-column grid */
  transition: all 0.3s ease;
  overflow-y: auto;
}
</style>