const resolveURL = "https://corsproxy.io/?https%3A%2F%2Fapi.naturalresources.wales%2Frivers-and-seas%2Fv1%2Fapi%2FStationData%3Fsubscription-key%3Ddfb919f8d2d34d1183de7ea650424eda";
const fetchURL = "https://corsproxy.io/?https%3A%2F%2Fapi.naturalresources.wales%2Frivers-and-seas%2Fv1%2Fapi%2FStationData%2Fhistorical%3Fsubscription-key%3Ddfb919f8d2d34d1183de7ea650424eda"

//rainfall and river level


export async function resolveNRW(dataItems) {
	const nrwData = await (await fetch(resolveURL)).json()
	let finalResults = [];
	for (const dataItem of nrwData) {
		const lat = Number(dataItem["latitude"])
		const lon = Number(dataItem["longitude"])
		const location = lat+","+lon;
		let x=0;
		for (const wantedDataItem of dataItems) {
			if (dataItem.parameters.some(x => x.paramNameEN == wantedDataItem.identifier)){
				let newResults = { ...wantedDataItem };
				newResults.location=location;
				newResults.locationID = dataItem.location;
				newResults.id=newResults.id+"_"+x;
				x++;
				finalResults.push(newResults);
			}
		}
	}
	return finalResults;
}


// to do pass in times here
export async function loadNRW(result) {
	const params = "&location="+result.locationID+"&parameter="+result.identifier;
	const nrwData = await (await fetch(fetchURL+encodeURIComponent(params))).json()
	let results = [];
	for (let i=0; i < nrwData.parameterReadings.length; i+=8) {
		const dataItem = nrwData.parameterReadings[i];
		results.push({x:dataItem.time, y:dataItem.value})
	}
	return results;
}