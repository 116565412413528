<template>
      <div class="filtering-bar-container">
        <div class="filtering-bar">
          <div class="filter-container">
            <div class="distance-filter">
              <label>Distance</label>
              <div class="slider-container">
                <span class="distance-label">0 km</span>
                <v-slider
                  v-model="distance"
                  min="0"
                  max="100"
                  hide-details
                  class="blue-slider"
                ></v-slider>
                <span class="distance-label">100 km</span>
              </div>
            </div>
            <div class="direction-filter">
              <label>Direction</label>
              <v-radio-group
                v-model="direction"
                row
                dense
                hide-details
              >
                <v-radio
                  label="↑ Upstream"
                  :disabled='true'
                  value="upstream"
                ></v-radio>
                <v-radio
                  label="↓ Downstream"
                  :disabled='true'
                  value="downstream"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          <v-btn
            class="search-button"
            @click="performSearch"
          >
            <v-icon left>mdi-magnify</v-icon>
            Search
          </v-btn>
        </div>
      </div>
</template>

<script setup>
import useEmitter from '@/plugins/useEmitter'
import { ref } from 'vue';
const emitter = useEmitter()


const distance = ref(0);
const direction = ref("")

const performSearch = () => {
     emitter.emit('restrictDistance', distance.value); 
}


</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px); /* Adjust based on your HeaderBar height */
}

.filtering-bar-container {
  flex: 0 0 auto;
  padding: 16px;
  background-color: #f5f5f5;
}

.content-container {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.left-sidebar {
  width: 25%;
  overflow-y: auto;
  padding: 16px;
}

.search-results-col {
  width: 25%;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.search-results-col.collapsed {
  width: 60px; /* Adjust based on your desired collapsed width */
}

.filtering-bar {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 8px 12px;
  flex-grow: 1;
  margin-right: 20px;
}

.search-icon {
  color: #757575;
  margin-right: 8px;
}

.search-input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  background-color: transparent;
}

.filter-container {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
}

.distance-filter, .direction-filter {
  margin-right: 24px;
}

.distance-filter label, .direction-filter label {
  font-size: 14px;
  color: #757575;
  margin-bottom: 8px;
  display: block;
}

.slider-container {
  display: flex;
  align-items: center;
  width: 300px;
}

.distance-label {
  font-size: 14px;
  color: #757575;
  width: 50px;
}

.blue-slider {
  flex-grow: 1;
}

.blue-slider :deep(.v-slider__track-background) {
  background-color: #E0E0E0 !important;
}

.blue-slider :deep(.v-slider__track-fill) {
  background-color: #2196F3 !important;
}

.blue-slider :deep(.v-slider__thumb) {
  background-color: #2196F3 !important;
}

.direction-filter :deep(.v-radio) {
  margin-right: 16px;
}

.direction-filter :deep(.v-radio .v-label) {
  font-size: 14px;
}

.search-button {
  height: 40px;
  background-color: #f5f5f5 !important;
  color: #000000 !important;
  text-transform: none;
  font-weight: normal;
  box-shadow: none !important;
  padding: 0 16px;
}

.search-button::before {
  background-color: transparent !important;
}

.search-button .v-icon {
  margin-right: 8px;
}
</style>