<template>
  <div :class="[ { 'parent-option': props.option.children.length > 0 }]">
          <v-switch
            v-model="enabled"
            class="custom-switch"
            inset
            hide-details
            color="success"
            @click:append='expanded=!expanded'
            @update:modelValue="update()"
            :append-icon="props.option.children.length > 0 ? expanded ? 'mdi-chevron-down' : 'mdi-chevron-right' : ''"
          >
            <template v-slot:label>
              <span :class="enabled ? 'active-label' : ''"> {{ option.label}} </span>   
            </template>
          </v-switch>
        </div>

        <template v-if="props.option.children.length > 0 && expanded">
          <div :style="'padding-left: '+props.index*5+'px;'" v-for="(child, childIndex) in props.option.children" :key="`${index}-${childIndex}`" child-option>
            <SampleTypeNode :option="child" :index="props.index+1" :overrideEnabled='enabled' @onStatusChange = "onChange"/>
          </div>
        </template>
</template>

<script setup>
import { ref, defineProps,toRef, watch, defineEmits } from 'vue';

const props = defineProps(['option','index','overrideEnabled'])
const emit = defineEmits(['onStatusChange'])

const enabled = toRef(props.overrideEnabled);
const expanded = ref(false);

watch(() => props.overrideEnabled, (newValue) => {
  enabled.value=newValue;
});

const update = () => {
  onChange({id:props.option.id,status:enabled.value,children:props.option.children}); 
}

const onChange = (changedItem) => {
  emit('onStatusChange',changedItem);
}


</script>

<style scoped>

.active-label {
  font-weight: bold;
  color: #000;
}

.custom-switch {
  margin-right: 0;
}

.expand-icon {
  margin-left: 4px;
}

/* Custom styles for v-switch */
:deep(.v-input--switch) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.v-input--switch .v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-input--switch__track) {
  opacity: 1 !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__track) {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

:deep(.v-input--switch__thumb) {
  color: white !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__thumb) {
  transform: translate(16px, 0);
}
</style>