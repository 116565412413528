import { BaseAPI } from './BaseAPI';

export class GISService extends BaseAPI {
    constructor(config) {
        super(config);
    }
    
    async search(name) {
        var data = await this.rawSearchQuery(name,"fwgw:Catchment");
        data = data.concat(await this.rawSearchQuery(name,"fwgw:SubCatchment"));
        data = data.concat(await this.rawSearchQuery(name,"schema:RiverBodyOfWater"));
        let newData = [];
        for (let dataItem of data) {
            var newObj = {};
            newObj["matched"] = dataItem.title;
            newObj["type"]=dataItem.type;
            newObj["coordinates"]=dataItem.geo;
            newData.push(newObj);
        }
        return newData;
    }

    async rawSearchQuery(name,type) {
        let lineType ="";
        if (type == "River") lineType ="line"; else lineType ="polygon";
        const query =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?title ?geo ?title where {
                ?s rdf:type `+type+`.
                ?s dct:title ?title .
                ?s rdf:type ?type . 
                ?s schema:geo ?b .
                ?b schema:`+lineType+` ?geo .
                FILTER contains(?title,"`+name+`")
            }
        `;
        var response = await this.query(query);
        return response;

    }

    getCatchments(callback) {
        const query = `
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            SELECT ?title ?geo WHERE {
                ?s rdf:type fwgw:Catchment .
                ?s dct:title ?title .
                ?s schema:geo ?b .
                ?b schema:polygon ?geo .
            }
        `;

        return this.queryCallback(query, callback);
    }

    getRivers(callback) {
        const query = `
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            SELECT ?title ?geo WHERE {
                ?s rdf:type schema:RiverBodyOfWater .
                ?s dct:title ?title .
                ?s schema:geo ?b .
                ?b schema:line ?geo .
            }
        `;

        return this.queryCallback(query,callback);
    }

    getSubCatchments(callback) {
        const query = `
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            SELECT ?title ?geo WHERE {
                ?s rdf:type fwgw:SubCatchment .
                ?s dct:title ?title .
                ?s schema:geo ?b .
                ?b schema:polygon ?geo .
            }
        `;

        return this.queryCallback(query, callback);
    }
}