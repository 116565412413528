<template>
  <div class="search-results-container" :class="{ 'collapsed': isCollapsed }">
    <div class="search-results-header">
      <h2 class="search-results-title" :class="{ 'hidden': isCollapsed }">Search results</h2>
      <div class="toggle-button" @click="toggleCollapse">
        <v-icon>{{ isCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
      </div>
    </div>
    <div class="search-results-content" v-show="!isCollapsed">
      <div v-if="!isCollapsed && filtering.length > 0" class="filter-box">
        <span class="filter-text" show="">Filtered To: {{ filtering[0] }} , {{ filtering[1] }} </span>
        <v-icon class="close-icon" @click="hideFilterBox">mdi-close</v-icon>
      </div>
      <div v-if="!isCollapsed && Number(distance) > 0 && filtering.length > 0" class="filter-box">
        <span class="filter-text">Filter Distance: {{ distance }}</span>
        <v-icon class="close-icon" @click="hideDistance">mdi-close</v-icon>
      </div>
      <div v-for="result in results.filter( x => inFilter(x))" :key="result.id" class="result-card">
        <div class="result-header">
          <!-- <div class="icon-container" :class="result.type">
            <v-icon class="icon">{{ getIcon(result.type) }}</v-icon>
          </div> -->
          <div class="info-icon-container" @click.stop="showMetadata(result)">
            <v-icon color="primary">mdi-information</v-icon>
          </div>
          <div v-if="!result.application.endsWith('GEOJSON') && !result.application.endsWith('GEOJSONAREA')"
               class="zoom-icon-container"
               @click="zoomToLocation(result)">
            <v-icon class="zoom-icon">mdi-magnify-plus</v-icon>
          </div>
          <div class="result-info">
            <h4 class="result-title">{{ result.title }}</h4>
            <p class="result-location">{{ result.location }}</p>
          </div>
        </div>

        <!-- Add the MetaDataDialog component -->
        <MetaDataDialog
          v-model="showMetaDataDialog"
          :result="selectedResult"
        />
        <div class="result-details">
          <div class="detail-item">
            <v-icon class="detail-icon fish-icon">mdi-fish</v-icon>
            <span class="detail-text">Dataset: {{ result.application.split("/")[result.application.split("/").length-1] }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="detail-icon data-icon">mdi-database</v-icon>
            <span class="detail-text">Data from: {{ result.organisation.split("/")[result.organisation.split("/").length-1] }}</span>
          </div>
        </div>
        <div class="result-buttons">
          <button v-if="result.application.endsWith('GEOJSON') && currentGeoJSONDataset==result.url" @click="currentGeoJSONDataset=''; emitter.emit('hidegeojson', result.url)" class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-map-outline</v-icon>
            Hide Results
          </button>
           <button v-else-if="result.application.endsWith('GEOJSON')" @click="currentGeoJSONDataset=result.url; emitter.emit('viewgeojson', {url:result.url, type: result.application})" class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-map-outline</v-icon>
            See Results
          </button>

           <button v-else @click="renderGraph(result)"  class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-chart-line</v-icon>
            See Graph
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, inject } from 'vue';

  import useEmitter from '@/plugins/useEmitter'
  import MetaDataDialog from './MetaDataDialog.vue';

  const DataService = inject('DataService');
  const ParameterService = inject('ParameterService');

  const currentGeoJSONDataset = ref("");
  const results = ref([]);
  const emitter = useEmitter()
  const isCollapsed = ref(true);
  const samplingApproaches = ref([]);
  const dataSources = ref([]);
  const suitabilityIndicators = ref([]);
  const timeIntervals = ref([]);
  const sampleTypes = ref([]);
  const mapLocation = ref({});
  const filtering = ref([]);
  const distance = ref(0);

  // const router = useRouter();

  const showMetaDataDialog = ref(false);
  const selectedResult = ref(null);

  const showMetadata = (result) => {
    selectedResult.value = result;
    showMetaDataDialog.value = true;
  };

  const zoomToLocation = async (result) => {
    // Added safety check here
    if (result.location) {
      emitter.emit('zoomTo', result.location);
    }
  }

  const inFilter = (item) => {
    if (filtering.value.length > 0 && item.location == undefined) return false;
    else if (item.location == undefined)  return true;
    let coords = item.location.split(',');
    coords[0]=Number(coords[0]).toFixed(4);
    coords[1]=Number(coords[1]).toFixed(4);
    if (distance.value == 0) {
      if (filtering.value.length > 0 && (coords[0] != filtering.value[1] || coords[1] != filtering.value[0])) return false
    } else {
      let distancecalc = calcCrow(coords,filtering.value)
      if (distancecalc > distance.value) return false;
    }
    if (!inGpsCoords(coords)) return false;
    return true;
  }

  const inGpsCoords = (coords) => {
    if ( mapLocation.value._sw != undefined && mapLocation.value._ne != undefined) {
      if (coords[0] > mapLocation.value._sw.lat && coords[0] < mapLocation.value._ne.lat) {
        if (coords[1] > mapLocation.value._sw.lng &&  coords[1] < mapLocation.value._ne.lng ) return true;
      }
    } else return true;
    return false
  }

  const toRad = (value) => {
    return value * Math.PI / 180;
  }


  const calcCrow = (coordA,coordB) => {
      var R = 6371;
      var dLat = toRad(coordB[1]-coordA[0]);
      var dLon = toRad(coordB[0]-coordA[1]);
      var lat1 = toRad(coordA[0]);
      var lat2 = toRad(coordB[1]);
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d;
  }

  const renderGraph = async (result) => {
    console.log("Rendering graph for:", result);

    // Extract dataset name from the application URL
    const dataset = result.application.split("/").pop();

    // Emit the graph data
    emitter.emit('viewgraph', result);

    // Emit the dataset name
    emitter.emit('datasetInfo', dataset);

  }

  const monitoringApplication = (val) => {
    if (val.endsWith("GEOJSON") || val.endsWith("GEOJSONAREA") ) return false;
    return true
  }

  const generateMonitoringStations = async function () {
    let monitoringStations = {};
    const organisations = await ParameterService.getDataSources();
    for (const organisation of organisations) {
       const organisationName = organisation.id.substr(organisation.id.lastIndexOf("/")+1);
       monitoringStations[organisationName] = [];
    }

    for (const result of results.value) {
      if (monitoringApplication(result.application)) {
         const organisation = result.organisation.substr(result.organisation.lastIndexOf("/")+1);
         // Add validation check for location
         if (result.location) {
           let val = monitoringStations[organisation].find(x => x.location == result.location);
           if (val == undefined) {
             monitoringStations[organisation].push({
               location: result.location,
               stimulus: [result.title]
             });
           } else {
             val.stimulus.push(result.title);
           }
         } else {
           console.warn(`Missing location for monitoring point in ${organisation}:`, result.title);
         }
      }
    }

    // Filter out empty arrays before returning
    for (const key in monitoringStations) {
      if (monitoringStations[key].length === 0) {
        delete monitoringStations[key];
      }
    }

    return monitoringStations;
  }

  // Clear existing monitoring points for all organisations
  const clearAllMonitoringPoints = async () => {
    const organisations = await ParameterService.getDataSources();
    for (const organisation of organisations) {
      const organisationName = organisation.id.substr(organisation.id.lastIndexOf("/")+1);
      emitter.emit('removeMonitoringPoints', organisationName);
    }
  };

  const populateResults = async function () {
    // Clear all existing points first
    await clearAllMonitoringPoints();

    results.value = await DataService.getDataSets(
      sampleTypes.value,
      dataSources.value,
      suitabilityIndicators.value,
      samplingApproaches.value,
      timeIntervals.value
    );

    if (results.value.length > 0) {
      isCollapsed.value = false;
    } else {
      isCollapsed.value = true;
    }

    // Add new monitoring points based on current results
    const monitoringStations = await generateMonitoringStations();
    for (const key in monitoringStations) {
      emitter.emit('addmonitoringpoints', [key, monitoringStations[key]]);
    }
  };

  // const getIcon = function(type) {
  //   const icons = {
  //     river: 'mdi-waves',
  //     catchment: 'mdi-map',
  //     samplingPoint: 'mdi-map-marker',
  //   };
  //   return icons[type] || 'mdi-help-circle';
  // }

  const toggleCollapse = function() {
    isCollapsed.value = !isCollapsed.value;
  }

  const hideFilterBox = function() {
    filtering.value = [];
  }

  const hideDistance = function() {
    distance.value = 0;
  }

  onMounted(() => {
      emitter.on("restrictDistance", value => {
          distance.value=value
      });

       emitter.on("filterToGPS", value => {
          filtering.value[0] = value[0].toFixed(4);
          filtering.value[1] = value[1].toFixed(4);
      });

      emitter.on("mapmoved", value => {
        mapLocation.value=value;
      });

      emitter.on("goHome", async () => {
          const monitoringStations = await generateMonitoringStations()
          setTimeout(function() {
            for (const key in monitoringStations) emitter.emit('addmonitoringpoints', [key,monitoringStations[key]]);
          }, 3000);

      });

      emitter.on("sampleType", values => {
        // Clear existing points before updating
        clearAllMonitoringPoints();

        sampleTypes.value=values.map(x => x.id);
        populateResults();
      });

      emitter.on("timeinterval", values => {
        // Clear existing points before updating
        clearAllMonitoringPoints();

        timeIntervals.value=values;
        populateResults();
      });

      emitter.on("samplingapproach", value => {
        if (value.status) {
          samplingApproaches.value.push(value.id);
        } else {
          samplingApproaches.value.splice(samplingApproaches.value.indexOf(value.id),1);

          // Clear existing points before updating
          clearAllMonitoringPoints();
        }
        populateResults();
      });

      emitter.on("datasource", value => {
        if (value.status) {
          dataSources.value.push(value.id);
        } else {
          dataSources.value.splice(dataSources.value.indexOf(value.id), 1);

          // Remove the monitoring points for this data source when toggled off
          const organisationName = value.id.substr(value.id.lastIndexOf("/")+1);
          emitter.emit('removeMonitoringPoints', organisationName);
        }
        populateResults();
      });

      emitter.on("suitabilityindicator", value => {
        if (value.status) {
          suitabilityIndicators.value.push(value.id);
        } else {
          suitabilityIndicators.value.splice(suitabilityIndicators.value.indexOf(value.id),1);

          // Clear points when indicator is toggled off
          clearAllMonitoringPoints();
        }

        populateResults();
      });
  })
</script>

<style scoped>
.search-results-container {
  position: absolute;
  top: 80px;
  width:250px;
  right: 0;
  background-color: #f0f4f8;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.search-results-container.collapsed {
  width: 60px;
  height: 60px;
}

.search-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4a90e2;
  padding: 12px 16px;
  color: #ffffff;
  height: 60px;
}

.search-results-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease;
}

.search-results-title.hidden {
  opacity: 0;
}

.toggle-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.2s;
}

.toggle-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.search-results-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
}

.result-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.result-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.icon-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.icon-container.river {
  background-color: #e3f2fd;
}

.icon-container.catchment {
  background-color: #e8f5e9;
}

.icon-container.samplingPoint {
  background-color: #e74c3c;
}

.icon {
  font-size: 20px;
}

.river .icon {
  color: #1976d2;
}

.catchment .icon {
  color: #43a047;
}

.samplingPoint .icon {
  color: #ffffff;
}

.result-info {
  flex-grow: 1;
}

.result-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
}

.result-location {
  font-size: 14px;
  color: #718096;
  margin: 2px 0 0 0;
}

.result-details {
  margin-bottom: 12px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.detail-icon {
  font-size: 18px;
  margin-right: 8px;
}

.fish-icon {
  color: #3498db;
}

.data-icon {
  color: #9b59b6;
}

.detail-text {
  font-size: 14px;
  color: #4a5568;
}

.result-buttons {
  justify-content: space-between;
}

.action-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.see-graph-btn {
  background-color: #3498db;
  color: #ffffff;
  margin-right: 8px;
}

.see-metadata-btn {
  background-color: #e2e8f0;
  color: #4a5568;
}

.see-graph-btn:hover {
  background-color: #2980b9;
}

.see-metadata-btn:hover {
  background-color: #cbd5e0;
}

.button-icon {
  margin-right: 6px;
  font-size: 18px;
}

.zoom-icon-container {
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #4a90e2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
}

.zoom-icon-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.zoom-icon-container:hover {
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.zoom-icon-container:hover::before {
  opacity: 1;
}

.zoom-icon {
  color: white;
  font-size: 24px;
  transition: transform 0.3s ease;
}

.zoom-icon-container:hover .zoom-icon {
  transform: scale(1.2);
}

.filter-box {
  background-color: #e3f2fd;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #1976d2;
  border-bottom: 1px solid #bbdefb;
}

.filter-text {
  font-weight: 500;
}

.close-icon {
  cursor: pointer;
  font-size: 18px;
  color: #1976d2;
}

.close-icon:hover {
  color: #1565c0;
}

/* Update existing styles to accommodate the new filter box */
.search-results-header {
  border-bottom: none; /* Remove the bottom border as it's now on the filter box */
}

.search-results-content {
  height: calc(100% - 120px); /* Adjust for the new filter box height */
}
</style>