


const url = "https://five.epicollect.net/api/export/entries/usk-castco-citizen-science?form_ref=b7f0dfdb1bff47c2bd9d6bbc0a5059d4_641af59307196&per_page=1000";


async function internalLoadEPICollect() {
	let rawData = await (await fetch(url+"&page=1")).json();
	const rawData2 = await (await fetch(url+"&page=1")).json();
	return rawData.data.entries.concat(rawData2.data.entries);
}

export async function resolveEPICOLLECT(epiCollectDataItems) {
	const epiCollectData = await internalLoadEPICollect();
	let finalResults = [];
	let locations = []
	let locationGPS = []
	for (const dataItem of epiCollectData) {
		const name = dataItem["Site"];
		if (locations.includes(name)) continue;
		locations.push(name);
		const lat = Number(dataItem["Site_Location"].latitude);
		const lon = Number(dataItem["Site_Location"].longitude);
		if (lat == 0 || lon ==0 ) continue;
		const location = lat+","+lon;
		locationGPS.push(location);
	}

	for (const resultObject of epiCollectDataItems) {
		for (let x=0; x < locationGPS.length; x++) {
			let newResults = { ...resultObject };
			newResults.id=newResults.id+"_"+x;
			x++;
			newResults.location=locationGPS[x];
			newResults.siteId=locations[x];
			finalResults.push(newResults);
		}
	}

	return finalResults;
}

export async function loadEPICOLLECT(result) {
	const epiCollectData = await internalLoadEPICollect();
	let results = [];
	for (const dataItem of epiCollectData) {
		if (dataItem.Site == result.siteId) results.push({x:dataItem.created_at, y:dataItem[result.identifier]})
	}
	return results;
}