import { DateTime } from "luxon";

function parseDateTime(dateTimeInput) {
	const dateTime = DateTime.fromFormat(dateTimeInput,"MM/dd/yyyy HH:mm");
	if (dateTime.isValid) return dateTime;
	console.log(dateTime);
}


export async function loadCSV(application) {
	const rawData = await (await fetch(application.url)).text();
	const lines  = rawData.split("\n");
	let results = [];
	for (let i=1; i < lines.length;i++) {
		const line = lines[i].split(",");
		const dateTime = parseDateTime(line[0]);
		results.push({x:dateTime.toISO() , y:Number(line[Number(application.identifier)])})

	}
	return results;
}